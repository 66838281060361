/** @jsx jsx */
import { jsx, css, withTheme } from "@emotion/react";
import * as React from "react";
import useHasMounted from "../hooks/useHasMounted";

import { urkunde as config } from "../config";
import { Toolbar } from "./Gallery";
import DotIcon from "./DotIcon";

const print = typeof window !== `undefined` ? require("print-js") : null;

// 950 x 1420

// 1900 x 2840

const width = 950,
  height = 1420,
  ratio = width / height,
  fontSize = Math.round(width * 0.077894737),
  padding = Math.round(fontSize * 0.2),
  inputTop = 0.798,
  maxTextWidth = Math.round(width * 0.850840336);

const Urkunde = withTheme(({ theme, achievement, ...props }) => {
  const isMounted = useHasMounted(false);
  const [imageLoaded, setImageLoaded] = React.useState();
  const [winSize, setWinSize] = React.useState();
  const [textValue, setTextValue] = React.useState(config.defaultText);
  const [isSmallScreen, setIsSmallScreen] = React.useState(false);
  const canvasRef = React.useRef(null);
  const imageRef = React.useRef(null);
  const inputRef = React.useRef(null);
  const bigToolbarRef = React.useRef(null);

  React.useEffect(() => {
    const namespace = "mediaquery";
    if (typeof window !== "undefined" && window.__WATCHERS__) {
      window.__WATCHERS__.size.addWatch({
        namespace,
        matchCondition: ([w, h]) => w >= 1024,
        onchange(f) {
          setWinSize(f);
        },
        onappear() {
          setIsSmallScreen(false);
        },
        ondisappear() {
          setIsSmallScreen(true);
        },
      });
    }
    return () => window.__WATCHERS__.size.removeNamespace(namespace);
  }, [setIsSmallScreen]);

  React.useEffect(() => {
    // load urkunde template image
    imageRef.current = new Image();
    imageRef.current.onload = () => setImageLoaded(true);
    imageRef.current.src = `/urkunde-${achievement.level}.jpg`;
  }, [achievement.level]);

  React.useLayoutEffect(() => {
    // draw urkunde with text
    const canvas = canvasRef.current;
    if (!canvas || !imageLoaded) {
      return;
    }
    const currentDate = new Date().toLocaleDateString("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    canvas.style.letterSpacing = "0.18em";
    const ctx = canvas.getContext("2d");
    const image = imageRef.current;
    ctx.drawImage(image, 0, 0);

    ctx.fillStyle = "#FFFFFF";
    ctx.textAlign = "center";
    ctx.font = "40px Headline";
    ctx.fillText(currentDate, width / 2, height * 0.889830508, maxTextWidth);

    ctx.fillStyle = "#FFD900";
    ctx.textAlign = "center";
    ctx.font = `${fontSize}px Headline`;
    const textWidth = ctx.measureText(textValue).width;
    const textScale = Math.min(maxTextWidth / textWidth, 1);
    ctx.font = `${fontSize * textScale}px Headline`;
    ctx.fillText(textValue, width / 2, height * 0.81920904, maxTextWidth);
  }, [canvasRef, imageLoaded, textValue]);

  React.useLayoutEffect(() => {
    // set size of input element
    const canvas = canvasRef.current;
    const input = inputRef.current;
    if (!canvas || !input) {
      return;
    }
    const canvasStyle = window.getComputedStyle(canvas, null);
    const curWidth = parseInt(canvasStyle.getPropertyValue("width"), 10);
    const curHeight = parseInt(canvasStyle.getPropertyValue("height"), 10);

    const scaleX = curWidth / width;
    const scaleY = curHeight / height;
    const scale = Math.min(scaleX, scaleY);
    const scaledFontSize = fontSize * scale;
    const scaledPadding = padding * scale;
    const heightInPercent = scaleY <= scaleX ? 1 : (scale * height) / curHeight;
    const newTop =
      (inputTop * heightInPercent + (1 - heightInPercent) / 2) * 100;
    input.style.cssText = `
        top: ${newTop}%;
        width: ${maxTextWidth * scale}px;
        height: ${scaledFontSize + scaledPadding + scaledPadding}px;
        padding: ${scaledPadding}px;
        font-size: ${scaledFontSize}px;
        line-height: ${scaledFontSize}px;
    `;
    if (bigToolbarRef.current) {
      bigToolbarRef.current.style.cssText = `
    margin-left: ${(scaleX > scaleY ? curHeight * ratio : curWidth) / 2 + 67}px;
`;
    }
  }, [isMounted, winSize]);

  if (!isMounted) {
    return null;
  }

  const handleTextChange = (e) => {
    setTextValue(e.target.value);
  };

  const handleTextFocus = (e) => {
    if (e.target.value === config.defaultText) {
      setTextValue("");
    }
  };
  const handleTextBlur = (e) => {
    if (e.target.value === "") {
      setTextValue(config.defaultText);
    }
  };

  const handleTextKeyUp = (e) => {
    if (e.keyCode === 13) {
      inputRef.current.blur();
    }
  };

  const handleDownload = (e) => {
    const canvas = canvasRef.current;

    if (!canvas) {
      e.preventDefault();
      console.warn("Keine Urkunde gefunden");
      return false;
    }
    e.target.href = canvas.toDataURL("image/png");
  };

  const handlePrint = (e) => {
    if (e) {
      e.preventDefault();
    }
    const canvas = canvasRef.current;
    if (!canvas) {
      console.warn("Keine Urkunde gefunden");
      return false;
    }
    if (!print) {
      console.warn("Kann nicht drucken");
      return false;
    }
    print({
      type: "image",
      printable: canvas.toDataURL("image/png"),
      base64: true,
      imageStyle:
        "max-height: 90vh; max-width: 90vw; display: block; height: auto; margin: auto;",
    });
  };

  const renderedTools = [
    {
      key: "download",
      as: "a",
      href: "#",
      color: "white",
      bgColor: theme.color.success,
      icon: "download",
      onClick: handleDownload,
      download: `TKKG-${achievement.name}-Urkunde.png`,
      title: "Urkunde herunterladen",
      small: isSmallScreen,
    },
    {
      key: "print",
      as: "button",
      color: theme.color.spot1,
      bgColor: "white",
      icon: "print",
      onClick: handlePrint,
      title: "Urkunde drucken",
      small: isSmallScreen,
    },
  ].map((t) => <DotIcon {...t} />);

  return (
    <div
      css={css`
        margin: 0 auto;
      `}
      {...props}
    >
      <div
        css={css`
          position: relative;
          ${theme.funcs.mediaquery(
            "xl",
            `
              height: calc(100vh - 60px);
            `
          )}
        `}
      >
        <div
          css={css`
            position: relative;
            ${theme.funcs.mediaquery(
              "xl",
              `
              padding: 0 160px;
            height: 100%;
              `
            )}
          `}
        >
          <canvas
            width={width}
            height={height}
            css={css`
              position: relative;
              display: block;
              width: 100%;
              height: 100%;
              object-fit: contain;
            `}
            ref={canvasRef}
          />
          <input
            ref={inputRef}
            css={css`
              position: absolute;
              left: 50%;
              transform: translate(-50%, -50%);
              top: ${inputTop}%;
              font-family: "Headline";
              font-size: ${fontSize}px;
              line-height: ${fontSize}px;
              appearance: none;
              border: 0;
              background: transparent;
              color: transparent;
              text-align: center;

              &:focus {
                color: ${theme.color.spot1};
                background: ${theme.color.yellow};
                outline: none;
              }
            `}
            onFocus={handleTextFocus}
            onBlur={handleTextBlur}
            onChange={handleTextChange}
            onKeyUp={handleTextKeyUp}
            value={textValue}
          />
        </div>
      </div>
      {!!isSmallScreen && <Toolbar>{renderedTools}</Toolbar>}
      {!isSmallScreen && (
        <div
          ref={bigToolbarRef}
          css={css`
            display: flex;
            flex-direction: column;
            position: fixed;
            left: 50%;
            top: 0;
            width: 60px;
            height: calc(100% - 100px);
            z-index: 999999;
            align-items: center;
            justify-content: center;

            > * + * {
              margin-top: 25px;
            }
          `}
        >
          {renderedTools}
        </div>
      )}
    </div>
  );
});

export default Urkunde;
