/** @jsx jsx */
import { css, jsx, withTheme } from "@emotion/react";
import { useState } from "react";
import { navigate } from "gatsby-link";

import LoginForm from "../../components/LoginForm";
import Urkunde from "../../components/Urkunde";
import ModalPortal from "../../components/ModalPortal";
import Veil from "../../components/Veil";
import FolderHeader from "../../components/FolderHeader";

import { getAchievement } from "../../services/achievements";
import { app } from "../../config";

const AppUrkundePage = withTheme(({ theme, location }) => {
  const [achievement, setAchievement] = useState(location?.state?.achievement);
  let scope = location.pathname;
  if (scope.endsWith("/")) {
    scope = scope.substring(0, scope.length - 1);
  }
  const pageData = app.routes.find((r) => r.path === scope);
  const message = pageData?.authMessage;

  const handleSuccess = (achievement) => {
    setAchievement(achievement);
  };
  const handleClose = () => {
    navigate(app.rootPath);
  };
  return (
    <div
      css={css`
        overflow: hidden;
      `}
    >
      {!achievement && (
        <ModalPortal>
          <Veil />
          <LoginForm
            onSuccess={handleSuccess}
            onClose={handleClose}
            validate={getAchievement}
            message={message}
          />
        </ModalPortal>
      )}
      {!!achievement && (
        <div
          css={css`
            padding: 106px 0 60px;
            min-height: calc(100vh - 160px);
            ${theme.funcs.mediaquery(
              "xl",
              `
              padding-top: 125px;
          `
            )}
          `}
        >
          <FolderHeader
            text={pageData.name}
            css={css`
              position: fixed;
              left: 0;
              top: 0;
              width: 100%;
              z-index: 999;
            `}
          />
          <Urkunde
            achievement={achievement}
            css={css`
              max-width: 88%;
            `}
          />
        </div>
      )}
    </div>
  );
});

export default AppUrkundePage;
